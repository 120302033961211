import { Session } from 'next-auth';

export function makeHeadersWithAuthorization({ access_token }: Session) {
  const headers = new Headers();
  const isDev = process.env.NODE_ENV === 'development';
  const hasDebugToken = !!process.env.DEBUG_TOKEN;

  const bearer =
    isDev && hasDebugToken ? process.env.DEBUG_TOKEN : access_token;

  headers.set('Authorization', `Bearer ${bearer}`);
  return headers;
}
