import { defineStyle } from '@chakra-ui/react';

export const semanticColors = defineStyle({
  'text&icon': {
    'tx-primary': 'grey.900',
    'tx-secondary': 'grey.500',
    'tx-contrast': 'white',
    'tx-accent': 'yellow-brand.400',
    'tx-error': 'red.400',
    'tx-warning': 'yellow.400',
  },
  background: {
    'bg-black': 'black',
    'bg-primary': 'grey.970',
    'bg-tertiary': 'grey.200',
    'bg-contrast': 'white',
    'bg-accent': 'yellow-brand.400',
    'bg-secondary': 'grey.850',
  },
  stroke: {
    'st-secondary': 'grey.800',
    'st-contrast': 'grey.200',
  },
  states: {
    'text&icon': {
      disabled: 'grey.800',
      'hover-contrast': 'grey.300',
      'disabled-contrast': 'grey.400',
    },
    background: {
      disabled: 'grey.600',
      'primary-hover': 'grey.900',
      'hover-contrast': 'grey.100',
      'accent-hover': 'yellow-brand.500',
    },
    stroke: {
      disabled: 'grey.900',
      error: 'red.400',
    },
  },
});

export const semanticTokens = defineStyle({
  colors: semanticColors,
});
