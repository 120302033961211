import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const md = definePartsStyle({
  field: {
    h: '56px',
  },
});

const outline = definePartsStyle({
  field: {
    textStyle: 'text-regular-lg',
    borderColor: 'stroke.st-secondary',
    _placeholder: { color: 'text&icon.tx-secondary' },
    _active: {
      borderColor: 'stroke.st-contrast',
    },
    _focus: {
      borderColor: 'stroke.st-contrast',
    },
    _focusVisible: {
      borderColor: 'stroke.st-contrast',
      boxShadow: 'none',
    },
    _invalid: {
      borderColor: 'states.stroke.error',
    },
    _disabled: {
      color: 'states.text&icon.disabled',
      borderColor: 'states.stroke.disabled',
      _placeholder: {
        color: 'states.text&icon.disabled',
      },
    },
  },
});

const baseStyle = definePartsStyle({
  field: {
    rounded: '20px',
    px: '12px',
  },
});

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { md },
  variants: { outline },
});
