import type { UserInfoStatistics } from '@/shared/api/codegen/demuzo';
import { convertToLargerUnit } from '@/shared/utils/convert-price';

export const mapApiUserStatisticsToStatistics = (
  statistics: UserInfoStatistics,
): UserInfoStatistics => {
  return {
    ...statistics,
    totalInvested: convertToLargerUnit(statistics.totalInvested),
    totalRevenue: convertToLargerUnit(statistics.totalRevenue),
  };
};
