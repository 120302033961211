import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const xs = defineStyle({
  w: '36px',
  h: '36px',
  textStyle: 'text-medium-xs',
  fontSize: '12px',
});

const sm = defineStyle({
  w: '40px',
  h: '40px',
  textStyle: 'text-medium-xs',
  fontSize: '12px',
});

const md = defineStyle({
  w: '70px',
  h: '70px',
  textStyle: 'text-medium-lg',
  fontSize: '16px',
});

const lg = defineStyle({
  w: '88px',
  h: '88px',
  textStyle: 'text-medium-lg',
  fontSize: '16px',
});

const xl = defineStyle({
  w: '140px',
  h: '140px',
  textStyle: 'text-medium-lg',
  fontSize: '16px',
});

const xl2 = defineStyle({
  w: '230px',
  h: '230px',
  textStyle: 'text-medium-lg',
  fontSize: '16px',
});

const sizes = {
  xs: definePartsStyle({ container: xs }),
  sm: definePartsStyle({ container: sm }),
  md: definePartsStyle({ container: md }),
  lg: definePartsStyle({ container: lg }),
  xl: definePartsStyle({ container: xl }),
  '2xl': definePartsStyle({ container: xl2 }),
};

const baseStyle = definePartsStyle({
  container: {
    rounded: 'full',
    bg: 'grey.700',
    color: 'black&white.white',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
});

export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
