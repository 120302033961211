import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {
    bg: 'rgba(0, 0, 0, 0.8)', //change the background
  },
  dialog: {
    bg: 'background.bg-primary',
    rounded: '30px',
  },
  dialogContainer: {
    zIndex: 40000,
  },
});

const burgerStyle = definePartsStyle({
  dialogContainer: {
    zIndex: 1400,
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { burgerStyle },
});
