import { REVALIDATE } from '@/entities/constants';
import { profileKeys } from '@/entities/profile/model/query-keys';
import {} from '@/shared/api';
import type {
  GetUsersV1ProfileError,
  UserInfo,
} from '@/shared/api/codegen/demuzo';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { mapApiUserStatisticsToStatistics } from '../lib';
import { userApi } from '@/shared/api/config';

export const getProfileInfoPrefetchConfig = (
  userId: string,
): FetchQueryOptions => ({
  queryKey: profileKeys.profileInfo(userId),
  queryFn: () =>
    userApi.getUsersV1UserIdInfo(
      { userId },
      { next: { revalidate: REVALIDATE } },
    ),
});

export const useGetProfileInfo = (userId: string) => {
  return useQuery<UserInfo, GetUsersV1ProfileError>({
    enabled: !!userId,
    queryKey: profileKeys.profileInfo(userId),
    retry: (count, error) => {
      if (error.code === 'NOT_FOUND') {
        return false;
      }
      return count < 3;
    },
    queryFn: async () => {
      const response = await userApi.getUsersV1UserIdInfo({ userId });

      const mappedStatistics = mapApiUserStatisticsToStatistics(
        response.statistics,
      );

      return {
        ...response,
        statistics: mappedStatistics,
      };
    },
  });
};
