import { defineStyle } from '@chakra-ui/react';

export const colors = defineStyle({
  grey: {
    970: '#121212',
    900: '#1A1A1A',
    850: '#222222',
    800: '#333333',
    700: '#4D4D4D',
    600: '#666666',
    500: '#808080',
    400: '#999999',
    300: '#B3B3B3',
    200: '#CCCCCC',
    100: '#E6E6E6',
  },
  'black&white': { black: '#000000', white: '#FFFFFF' },
  green: {
    500: '#00B273',
    400: '#00CC83',
    50: '#E3FFF5',
  },
  red: {
    400: '#FF6363',
    50: '#FFE5E5',
  },
  'yellow-brand': {
    500: '#CEE053',
    400: '#E5FA5C',
  },
  yellow: {
    400: '#E6B73F',
  },
  whiteAlpha: {
    200: 'rgba(255, 255, 255, 0.2)',
    100: 'rgba(255, 255, 255, 0.1)',
  },
});
