import type { Product } from '@/shared/api/codegen/demuzo';
import { convertToLargerUnit } from '@/shared/utils/convert-price';

export const mapApiProductToProduct = (product: Product): Product => {
  return {
    ...product,
    crowdFundingCampaign: {
      ...product.crowdFundingCampaign,
      userInvested: product.crowdFundingCampaign.userInvested
        ? convertToLargerUnit(product.crowdFundingCampaign.userInvested)
        : null,
      userRevenue: product.crowdFundingCampaign.userRevenue
        ? convertToLargerUnit(product.crowdFundingCampaign.userRevenue)
        : null,
      successThreshold: convertToLargerUnit(
        product.crowdFundingCampaign.successThreshold,
      ),
      sharePrice: convertToLargerUnit(product.crowdFundingCampaign.sharePrice),
      totalInvested: convertToLargerUnit(
        product.crowdFundingCampaign.totalInvested,
      ),
    },
  };
};
