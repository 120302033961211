import { breadcrumbAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);

export const baseStyle = definePartsStyle({
  link: {
    textStyle: 'text-medium-sm',
    color: 'text&icon.tx-secondary',
  },
  separator: {
    color: 'text&icon.tx-secondary',
    display: 'flex',
    alignItems: 'center',
    m: 0,
  },
});
export const breadcrumbScheme = defineMultiStyleConfig({ baseStyle });
