import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bg: 'background.bg-tertiary',
    border: 'none',
    color: 'grey.900',
    py: '20px',
    _focus: {
      bg: 'background.bg-tertiary',
    },
  },
  button: {
    textStyle: 'text-semibold-sm',
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.04em',
    p: '0 10px',
    justifyContent: 'space-between',
    gap: '10px',
    textAlign: 'left',
    w: 'full',
    _active: {
      bg: 'background.bg-tertiary',
    },
    _focusVisible: {
      bg: 'background.bg-tertiary',
    },
    _focus: {
      bg: 'background.bg-tertiary',
    },
  },
  panel: {
    textStyle: 'text-regular-sm',
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.04em',
    p: '20px 20px 0',
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
