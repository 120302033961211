import type { Transactions } from '@/shared/api/codegen/demuzo';
import { convertToLargerUnit } from '@/shared/utils/convert-price';

export const mapApiTransactionsToTransactions = (
  transaction: Transactions,
): Transactions => {
  return {
    ...transaction,
    amount: convertToLargerUnit(transaction.amount),
  };
};
