import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'background.bg-contrast',
    _disabled: {
      bg: 'states.text&icon.hover-contrast',
    },
  },
  track: {
    bg: 'background.bg-secondary',
    _checked: {
      bg: 'background.bg-accent',
      _disabled: {
        bg: 'states.background.disabled',
      },
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'sm',
  },
  sizes: {
    sm: {
      track: {
        height: '27px',
        width: '47px',
      },
      thumb: {
        width: '27px',
        height: '27px',
        _checked: {
          transform: 'translateX(20px)',
        },
      },
    },
  },
});
