import { ButtonColorSchemesOutline, ButtonColorSchemesSolid } from '../types';

export const buttonColorSchemesOutline: ButtonColorSchemesOutline = {
  red: {
    bg: 'transparent',
    color: 'text&icon.tx-error',
    _hover: {
      bg: 'transparent',
    },
    _active: {
      bg: 'transparent',
    },
    _disabled: {
      bg: 'transparent',
      color: 'states.text&icon.text&icon',
    },
    _loading: {
      bg: 'transparent',
      color: 'text&icon.tx-error',
    },
  },
  white: {
    bg: 'transparent',
    color: 'text&icon.tx-contrast',
    _hover: {
      bg: 'states.background.hover-contrast',
    },
    _active: {
      bg: 'states.background.hover-contrast',
    },
    _disabled: {
      bg: 'states.background.disabled',
      color: 'states.text&icon.disabled-contrast',
    },
    _loading: {
      bg: 'transparent',
      color: 'text&icon.tx-contrast',
    },
  },
};

export const buttonColorSchemeSolid: ButtonColorSchemesSolid = {
  white: {
    bg: 'background.bg-contrast',
    color: 'text&icon.tx-primary',
    _hover: {
      bg: 'states.background.hover-contrast',
    },
    _active: {
      bg: 'states.background.hover-contrast',
    },
    _disabled: {
      bg: 'states.background.disabled',
      color: 'states.text&icon.disabled-contrast',
    },
    _loading: {
      bg: 'background.bg-contrast',
      color: 'text&icon.tx-primary',
    },
  },
  green: {
    bg: 'background.bg-accent',
    color: 'text&icon.tx-primary',
    _hover: {
      bg: 'states.background.accent-hover !important',
    },
    _active: {
      bg: 'states.background.accent-hover',
    },
    _disabled: {
      bg: 'states.background.disabled',
      color: 'states.text&icon.disabled-contrast',
    },
    _loading: {
      bg: 'background.bg-accent',
      color: 'text&icon.tx-primary',
    },
  },
  transparent: {
    bg: 'transparent',
    color: 'text&icon.tx-contrast',
    _hover: {
      bg: 'whiteAlpha.100',
    },
    _active: {
      bg: 'whiteAlpha.100',
    },
    _disabled: {
      bg: 'transparent',
      color: 'states.text&icon.disabled',
    },
    _loading: {
      bg: 'transparent',
      color: 'text&icon.tx-contrast',
    },
  },
  black: {
    bg: 'background.bg-primary',
    color: 'text&icon.tx-contrast',
    _hover: {
      bg: 'states.background.primary-hover',
    },
    _active: {
      bg: 'states.background.primary-hover',
    },
    _disabled: {
      bg: 'states.background.hover-contrast',
      color: 'states.text&icon.disabled-contrast',
    },
    _loading: {
      bg: 'background.bg-primary',
      color: 'text&icon.tx-contrast',
    },
  },
};
