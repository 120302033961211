import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const md = definePartsStyle({
  control: {
    w: '18px',
    h: '18px',
  },
});

const solid = definePartsStyle({
  label: {
    color: 'text&icon.tx-contrast',
    _disabled: {
      color: 'states.text&icon.disabled',
    },
  },
  control: {
    color: '#000000',
    borderColor: 'text&icon.tx-secondary',
    _checked: {
      color: '#000000',
      borderColor: 'text&icon.tx-accent',
      backgroundColor: 'text&icon.tx-accent',
      borderWidth: '3px',
      _focusVisible: {
        borderColor: 'text&icon.tx-accent',
        backgroundColor: 'text&icon.tx-accent',
      },
      _focus: {
        borderColor: 'text&icon.tx-accent',
        backgroundColor: 'text&icon.tx-accent',
      },
    },
    _disabled: {
      color: '#000000',
      borderColor: 'states.text&icon.disabled',
      bgColor: 'transparent',
      _checked: {
        color: '#000000',
        borderColor: 'states.text&icon.disabled',
        backgroundColor: 'states.text&icon.disabled',
        _focusVisible: {
          borderColor: 'states.text&icon.disabled',
          backgroundColor: 'states.text&icon.disabled',
        },
        _focus: {
          borderColor: 'states.text&icon.disabled',
          backgroundColor: 'states.text&icon.disabled',
        },
      },
    },
  },
});

const baseStyle = definePartsStyle({
  label: {
    textStyle: 'text-regular-lg',
    ml: '18px',
  },
  control: {
    borderWidth: '1.8px',
  },
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { md },
  variants: { solid },
  defaultProps: { variant: 'solid' },
});
