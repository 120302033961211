import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { alertStatusColors } from '../foundations/alert-status-colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  const currentIconColor =
    alertStatusColors[props.status as keyof typeof alertStatusColors];

  if (!currentIconColor) {
    return {};
  }

  return {
    container: {
      justifyContent: 'space-between',
      rounded: '20px',
      bg: '',
      bgColor: 'background.bg-secondary',
      p: '15px 15px 15px 20px',
    },
    title: {
      display: 'none',
    },
    icon: {
      m: 0,
      color: currentIconColor,
    },
    description: {
      textStyle: 'text-regular-lg',
      color: 'text&icon.tx-contrast',
    },
  };
});

export const alertTheme = defineMultiStyleConfig({ baseStyle, variants: {} });
