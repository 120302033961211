import { IBM_Plex_Mono, IBM_Plex_Sans } from 'next/font/google';
import localFont from 'next/font/local';

export const ibmPlexMono = IBM_Plex_Mono({
  preload: true,
  subsets: ['latin', 'cyrillic'],
  weight: ['600', '500', '400'],
  variable: '--font-ibm-mono',
});

export const ibmPlexSans = IBM_Plex_Sans({
  preload: true,
  subsets: ['latin', 'cyrillic'],
  weight: ['600', '500', '400'],
  variable: '--font-ibm-sans',
});

export const naNMetrifyAWide = localFont({
  preload: true,
  src: '../ui-kit/assets/NaNMetrify-A-Latin-Wide-Bold/WOFF/NaNMetrifyAWide-Bld.woff2',
  weight: '700',
  style: 'normal',
  variable: '--font-metrify',
});

export const fontsVariables = [
  ibmPlexSans.variable,
  ibmPlexMono.variable,
  naNMetrifyAWide.variable,
].join(' ');
