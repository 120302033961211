import { defineStyle } from '@chakra-ui/react';

export const layerStyles = defineStyle({
  'gradient-border': {
    bgGradient:
      'linear-gradient(209.96deg, #3E3D3D 2.02%, rgba(77, 77, 77, 0) 32%)',
  },
  'gradient-border-positive': {
    bgGradient:
      'linear-gradient(212.67deg, #00CC83 0%, rgba(0, 204, 131, 0) 100%)',
  },
  'gradient-border-negative': {
    bgGradient:
      'linear-gradient(212.67deg, #FF6363 0%, rgba(255, 99, 99, 0) 100%)',
  },
  'gradient-border-pause': {
    bgGradient:
      'linear-gradient(212.67deg, rgba(230, 183, 63, 1) 0%, rgba(255, 99, 99, 0) 100%)',
  },
  'gradient-border-update': {
    bgGradient:
      'linear-gradient(212.67deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%)',
  },
  'header-gradient': {
    bgGradient:
      'linear-gradient(to-t , rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
  },
});
