import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const md = definePartsStyle({
  tab: {
    p: '15px 12px',
  },
});

const baseStyle = definePartsStyle({
  tab: {
    rounded: '32px',
    textStyle: 'text-medium-lg',
    _selected: {
      color: 'text&icon.tx-primary',
      bg: 'background.bg-accent',
    },
  },
  tablist: {
    gap: '10px',
    p: '20px',
  },
});

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { md },
});
