import { profileKeys } from '@/entities/profile/model/query-keys';
import {} from '@/shared/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { mapApiProductToProduct } from '../lib';
import type { GetUserPortfolio200 } from '@/shared/api/codegen/demuzo';
import { LIMIT } from '@/entities/constants';
import { portfolioApi, userApi } from '@/shared/api/config';

export const useGetPortfolio = (userId?: string) => {
  return useInfiniteQuery({
    enabled: !!userId,
    queryKey: profileKeys.profilePortfolio(userId!),
    getNextPageParam: (lastPage: GetUserPortfolio200) => {
      if (lastPage.total > lastPage.limit + lastPage.offset) {
        return lastPage.offset / lastPage.limit + 1;
      }
      return undefined;
    },
    initialPageParam: 0,
    queryFn: async ({ pageParam }) => {
      const response = await portfolioApi.getPortfolioV1UserPortfoliosUserId({
        limit: LIMIT,
        offset: pageParam * 10,
        userId: userId!,
      });

      const mappedProducts = response.items.map(mapApiProductToProduct);

      return {
        ...response,
        items: mappedProducts,
      };
    },
  });
};
