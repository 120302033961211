import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const lg = definePartsStyle({
  container: {
    px: '15px',
    h: '42px',
  },
});

const sm = definePartsStyle({
  container: {
    px: '10px',
    h: '28px',
  },
});

const container = defineStyle({
  fontFamily: `'Inter', sans-serif`,
  border: '1px solid',
  fontWeight: '500',
  letterSpacing: '-0.04em',
  fontSize: '16px',
  lineHeight: '19.2px',
  borderColor: 'stroke.st-secondary',
});

const solid = definePartsStyle({
  container: container,
  label: {
    fontWeight: '500',
    letterSpacing: '-0.04em',
    fontSize: '16px',
    lineHeight: '19.2px',
    color: 'text&icon.tx-contrast',
  },
});

const subtle = definePartsStyle({
  container: {
    fontFamily: `'Inter', sans-serif`,
    border: 'none',
    fontWeight: '500',
    letterSpacing: '-0.02em',
    fontSize: '12px',
    lineHeight: '16.8px',
    bg: 'stroke.st-secondary',
    color: 'text&icon.tx-contrast',
  },
  label: {
    fontWeight: '500',
    letterSpacing: '-0.02em',
    fontSize: '12px',
    lineHeight: '16.8px',
    color: 'text&icon.tx-contrast',
  },
});

const baseStyle = definePartsStyle({
  container: {
    rounded: '40px',
    bg: 'transparent',
  },
  label: {
    color: 'text&icon.tx-contrast',
  },
});

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { solid, subtle },
  sizes: { lg, sm },
  defaultProps: { variant: 'solid', size: 'lg' },
});
