import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const md = defineStyle({
  h: '56px',
});

const outline = defineStyle({
  caretColor: '#E5FA5C',
  textStyle: 'text-regular-lg',
  borderColor: 'stroke.st-secondary',
  _placeholder: { color: 'text&icon.tx-secondary' },
  _active: {
    borderColor: 'stroke.st-contrast',
  },
  _focus: {
    borderColor: 'stroke.st-contrast',
  },
  _focusVisible: {
    borderColor: 'stroke.st-contrast',
    boxShadow: 'none',
  },
  _invalid: {
    borderColor: 'states.stroke.error',
  },
  _disabled: {
    color: 'states.text&icon.disabled',
    borderColor: 'states.stroke.disabled',
    _placeholder: {
      color: 'states.text&icon.disabled',
    },
  },
});

const baseStyle = defineStyle({
  rounded: '20px',
  px: '12px',
});

export const textareaTheme = defineStyleConfig({
  baseStyle,
  sizes: { md },
  variants: { outline },
});
