import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys);

export const baseStyle = definePartsStyle({
  dialogContainer: {
    zIndex: 2000,
  },
});
export const drawerScheme = defineMultiStyleConfig({ baseStyle });
