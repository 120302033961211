import { FontMetrics } from '@capsizecss/core';

export const metrifyMetrics: FontMetrics = {
  familyName: 'NaN Metrify A Wide',
  fullName: 'NaN Metrify A Wide Bold',
  postscriptName: 'NaNMetrifyAWide-Bld',
  capHeight: 1400,
  ascent: 1600,
  descent: -400,
  lineGap: 400,
  unitsPerEm: 2000,
  xHeight: 1014,
  xWidthAvg: 1133,
  subsets: {
    latin: {
      xWidthAvg: 1133,
    },
    thai: {
      xWidthAvg: 1714,
    },
  },
};
